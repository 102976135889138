import { template as template_59ef8768a23a426386780fc51d223d41 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_59ef8768a23a426386780fc51d223d41(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
