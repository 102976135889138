import { template as template_91f84ec17d8d4946a25c3990cbbb336f } from "@ember/template-compiler";
const WelcomeHeader = template_91f84ec17d8d4946a25c3990cbbb336f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
