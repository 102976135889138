import { template as template_95668dbd8f7d4b018d87301492c93dc5 } from "@ember/template-compiler";
const FKLabel = template_95668dbd8f7d4b018d87301492c93dc5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
